import { TREE_I18N_KEY, TREE_I18N_MAP } from "./tree";

export const EMAIL_TEMPLATE_KEYS: Record<string, string> = {
	"activate-account": "Account aktivieren",
	"appointment-cancelled-email-coach": "Coaching-Termin gekündigt (Coach)",
	"appointment-cancelled-email-user": "Coaching-Termin gekündigt (Nutzer)",
	"appointment-email-coach": "Termineinladung (Coach)",
	"appointment-email-user": "Termineinladung (Nutzer)",
	"asnyc-element-task-was-evaluated": "Einreichung beendet",
	"async-element-task-ready-for-evaluation": "Einreichung bewertbar",
	"billing-invoice-email": "Rechnung",
	"billing-order-fulfilled": "Bestellung erfolgreich",
	"billing-payment-processor-email": "Zahlungsanbieter",
	"billing-sepa-mandate-email": "SEPA-Mandat",
	"coach-statement-email": "Coaching-Abrechnung (Coach)",
	"external-license-issued": "Externe Lizenz erhalten",
	"forgot-password": "Passwort vergessen",
	"ihk-certificate-ordered-send-to-ihk": "IHK-Zertifikat (IHK)",
	"ihk-certificate-ordered-send-to-recipient": "IHK-Zertifikat (Nutzer)",
	"invitation-to-account": "Accounteinladung",
	"remind-register-participation": "Teilnahmebest. Erinnerung",
	"admin-feedback-v2": "Feedback erhalten",
	"job-preparation-appointment-email-coach": "Termineinladung Job-Vorbereitung (Coach)",
	"job-preparation-license-granted": "Lizenz für Job-Vorbereitung erhalten",
	"first-interview-user-email": "Einladung 1. Interview (Nutzer)",
	"first-interview-business-email": "Einladung 1. Interview (Firma)",
	"second-interview-user-email": "Einladung 2. Interview (Nutzer)",
	"second-interview-business-email": "Einladung 2. Interview (Firma)",
	"inform-about-fee-for-hire-email": "Rechnung für Anstellung über Placement",
	"user-is-interested-email": "Nutzer ist interessiert an Jobangebot",
	"remind-to-invite-for-interview-email": "Erinnerung Einladung zum 2. Interview",
	"user-has-accepted-matching-email": "Nutzer hat neue akzeptierte Matchings",
	"new-matching-results-user-email": "Neue Matching Ergebnisse sind verfügbar (Nutzer)",
	"new-matching-results-business-email": "Neue Matching Ergebnisse sind verfügbar (Firma)",
	"remind-user-to-accept-or-decline-second-interview-email":
		"Erinnerung zur Bestätigung des zweiten Interview Termins (Nutzer)",
	"inform-business-about-available-dossier-email": "Dossier steht für Firma zum Download bereit",
	"report-system": "Meldungseingang ans System",
	"report-success": "Meldungseingangsbestätigung an Endnutzer",
	"business-is-interested-email": "Ein Unternehmen zeigt interesse an deinem Profil (Nutzer)",
	"ask-company-if-contract-was-concluded-email":
		"Wurde der Vertrag abgeschlossen? (Kontakt Person)",
	"bank-transfer-order-confirmation": "Bestellbestätigung (Banküberweisung)",
	"stripe-onboarding": "Vertragspartner Onboarding (Stripe)",
	"avgs-potentialanalysis-finished": "AVGS Potentialanalyse abgeschlossen",
	"licenses-created-by-admin-report":
		"CSV-Übersicht über eingelöste Lizenzen (vom Admin erstellt)",
	"inform-about-closed-profile-after-matching": "Nutzerprofil wurde nach Matching geschlossen",
};

export const EMAIL_VARIABLES: Record<string, string> = {
	city: "Stadt die der Benutzer bei der Adresse angeben hat",
	efnNumber: "EFN-Nummer des Benutzers",
	fachrichtung: "Fachrichtung des Benutzers",
	firstName: "Vorname des Benutzers",
	globalStatus: "Status des Benutzers",
	lastName: "Nachname des Benutzers",
	oneKeyId: "OneKey-Id des Benutzers",
	salutation: "Anrede des Benutzers (Herr / Frau)",
	title: "Titel des Benutzers",
	authenticationToken: "Aktivierungs-Token (muss in den Link)",
	feedbackIdBase64: "Interne Id dieses Feedbacks",
	feedbackContent: "Feedback Inhalt",
	frontendUrl: "URL des Nutzersystem-User-Frontends",
	brand: "Name der Marke unter der sich der Kunde registriert hat",
	brandUrl: "URL der Marke auf der sich der Kunde registriert hat",
	username: "Benutzername des Nutzers, im Regelfall die E-Mail-Adresse",
	brandIdBase64:
		"ID der Marke mit der sich registriert wurde, für Verwendung in Links zum Frontend.",
	containerTitle: "Titel des Inhalts.",
	containerIdBase64: "ID des Inhaltes, kann für Links verwendet werden.",
	brandDomain:
		"Domain der Marke über die sich ein Nutzer angemeldet hat (bei www.infectopharm.com zB infectopharm.com)",
	brandIcon: "Marken-Bild",
	brandColor: "Farbcode der Marke",
	lmsUrl: "URL des LMS",
	rootNodeTitle: `Titel des ${TREE_I18N_MAP(TREE_I18N_KEY.treeAttributiveGenitive)}.`,
	rootNodeIdBase64: `ID des  ${TREE_I18N_MAP(
		TREE_I18N_KEY.treeAttributiveGenitive,
	)}, kann für Links verwendet werden.`,
	contentNodeTitle: `Titel des ${TREE_I18N_MAP(TREE_I18N_KEY.contentNodeAttributiveGenitive)}.`,
	contentNodeIdBase64: `ID des ${TREE_I18N_MAP(
		TREE_I18N_KEY.contentNodeAttributiveGenitive,
	)}, kann für Links verwendet werden.`,
	linkToOffer: "URL zum Job-Vorbereitungs Coaching",
	participantPlacementSystemUrl: "URl des Teilnehmers zum Placement System",
	companyTitle: "Name des Unternehmens",
	jobListingTitle: "Titel des Jobangebots",
	jobListingId: "Id des Jobangebots",
	interviewDayOneDate: "Datum des 1. Interviews",
	interviewDayTwoDate: "Datum des 2. Interviews",
	linkToInterviewMeeting: "Link zum Interview",
	referenceNumber: "Referenznummer",
	contactPerson: "Kontakt Person des Unternehmens",
	managerFirstName: "Vorname des Managers",
	mangerLastName: "Nachname des Managers",
	linkToLogin: "Link zum CA Login (mit Weiterleitung zu TJM)",
	deadLineMatching: "Deadline des Matchings",
	jobListingInterestedYes: "'Ja', wenn interessiert, sonst 'Nein'",
	orderId: "Bestellnummer",
	name: "Name des Vertragspartner",
	onboardingLink: "Link zum Onboarding",
	clientName: "Name des AVGS Klienten",
	coachName: "Name des AVGS Coaches",
	adminURLToPA: "Link zur Potentialanalyse im Adminbereich",
	nextMatchingRoundDate: "Datum der nächsten Matching-Runde",
};
